
// export const FetchData = async () => {
//     try {
//         const resp = await fetch('https://pokeapi.co/api/v2/pokemon/ditto');
//         if (resp.status !== 200) {
//             throw new Error('Error al obtener datos de la API');
//         }
//         return await resp.json();
//     } catch (error) {
//         throw new Error('Error en la solicitud:', error);
//     }
// };


export const ValidatePasarela = async (value) => {
    try {
        const resp = await fetch('https://api.cabalgar.com.co/api/validate/pasarela', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': 'zOKn!4R}OS2#D[MCd!dKc0;~CLIDS_+mXhx?e_$Co)4/E#13Nc|cO~e\'F;Z#Fx:z'
            },
            body: JSON.stringify({
                "reference_bol": value
            })
        });
        if (resp.status !== 200) {
            throw new Error('Error al obtener datos de la API');
        }
        return await resp.json();
    } catch (error) {
        throw new Error('Error en la solicitud:', error);
    }
};


export const desactivarBoleta = async (value) => {
    try {
        const resp = await fetch('https://api.cabalgar.com.co/api/desactivar/boleta', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': 'zOKn!4R}OS2#D[MCd!dKc0;~CLIDS_+mXhx?e_$Co)4/E#13Nc|cO~e\'F;Z#Fx:z'
            },
            body: JSON.stringify({
                "reference_bol": value
            })
        });
        if (resp.status !== 200) {
            throw new Error('Error al obtener datos de la API');
        }
        return await resp.json();
    } catch (error) {
        throw new Error('Error en la solicitud:', error);
    }
};