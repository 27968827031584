import React, { useEffect, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import { ValidatePasarela, desactivarBoleta } from './Services/Test';
import 'bootstrap/dist/css/bootstrap.min.css';

function Test() {
    const [code, setCode] = useState('');
    const [data, setData] = useState({
        "estado": '',
        "id_boleta": '',
        "reference": '',
        "reference_bol": ''
    });

    const onScanError = (errorMessage) => { // evento error al leer QR.
        alert("error al leer QR", errorMessage);
    };

    const onScanSuccess = (qrCodeMessage) => { // evento al capturar lectura QR.
        setCode(qrCodeMessage);
    };

    const startCamera = () => {
        const scanner = new Html5Qrcode('qr-reader');
        scanner.start(
            { facingMode: 'environment' }, // Usa la cámara trasera del dispositivo
            onScanError,
            onScanSuccess,
        );
    };

    const stopCamera = () => {
        window.location.reload();
        setColor("white");
    }

    const [color, setColor] = useState('white');
    useEffect(() => {

        if (code !== '') {
            const validatePasarela = async () => {
                // '68493846653488'
                const { info } = await ValidatePasarela(code);
                if (info) {  // si el objeto es distinto de null, 
                    setData(info);
                    setColor(info.estado ? "green" : "blue");
                    if (info.estado) {
                        desactivarBoleta(code);
                    }
                } else {
                    setColor("red");
                    setData({
                        "estado": '',
                        "id_boleta": '',
                        "reference": '',
                        "reference_bol": ''
                    });
                    alert("Referencia no valida.");
                }
            };
            validatePasarela();
        } else {
            setColor("white");
        }
    }, [code]);
    return (
        <>
            <div className="container mt-3 text-center" style={{ "backgroundColor": color, "width": "80%", "height": "80%" }}>
                <div className="card-body">
                    <h2 className="card-title mb-4">LECTOR QR</h2>
                    <div className="mb-3 border border-2" id="qr-reader"></div>
                    <button onClick={() => startCamera()}
                        className='btn btn-primary m-3'>Iniciar captura</button>
                    <button onClick={() => stopCamera()}
                        className='btn btn-danger'>Detener captura</button>
                </div>
            </div>
            <div className='text-center mt-3'>
                <h4>
                    <ul style={{ "listStyle": "none" }}>
                        <li>estado :{data.estado}</li>
                        <li>referencia compra : {data.reference}</li>
                        <li>reference QR : {data.reference_bol}</li>
                    </ul>
                </h4>
            </div>
        </>
    );
}

export default Test;
